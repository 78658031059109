import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams, Redirect } from 'react-router-dom'
import { toast } from 'react-hot-toast';

import Header from './Header';
import Footer from './Footer'

import { addServerErrors, ALLOWED_PLATFORMS, getFieldSchema, register, useQuery } from '../helpers';
import { getFieldComponent, FieldMarkup } from './fields';

interface IFormFieldMap {
  [key: string]: IFormField
}

interface IFormField {
  type: 'field' | 'string' | 'boolean' | 'email'
  label: string
  required: boolean
  help_text?: null,
  max_length?: null,
  choices?: any[],
}

interface IRouteParams {
  platform: string
}

const OnBoardingForm = () => {
  const { platform } = useParams<IRouteParams>();
  const [tac, setTac] = useState();
  const [formFields, setFormFields] = useState<IFormFieldMap>({});
  const query = useQuery();
  const formMethods = useForm({
    mode: 'all',
  });

  const {
    errors,
    handleSubmit,
    setError,
  } = formMethods;

  useEffect(() => {
    async function getFormFields() {
      const loader = toast.loading('Ladataan...');
      try {
        const { fields, tac } = await getFieldSchema(`${process.env.REACT_APP_API_URL}/onboard/${platform}`)
        setFormFields(fields);
        setTac(tac);
      } catch (e) {
        console.log(e);
        toast.error(e);
      } finally {
        toast.dismiss(loader)
      }
    }

    if (ALLOWED_PLATFORMS.includes(platform)) {
      getFormFields();
    }
  }, [setTac, setFormFields, platform]);

  if (!ALLOWED_PLATFORMS.includes(platform)) {
    return <Redirect to="/"/>
  }

  const onSubmit = async (data: any) => {
    if (!data?.shipping_countries?.length) {
      toast.error('Lisää ainakin yksi toimitustapa!');
    } else {
      const feed_url = query.get('api_url')
      const newData = { ...data, feed_url }

      const res = await register(`${process.env.REACT_APP_API_URL}/onboard/${platform}`, newData);

      if (res.status !== 201) {
        const { data } = res;

        addServerErrors(data, setError);

        // console.log(data);
      } else {
        toast.success('Kiitos rekisteröitymisestä!');

        setTimeout(() => {
          window.location.href = query.get('return_url') as string;
        }, 2000)
      }
    }
  }

  return (
    <div className="onboarding">
      <Header onSubmit={handleSubmit(onSubmit)}/>
      <div className="container">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} className="onboarding-form">
            {Object.keys(formFields).map((key) => {

              const fieldType = formFields[key].type;
              const Field = getFieldComponent(fieldType)
              const fieldProps = formFields[key]

              return Field !== null && (
                <FieldMarkup key={key} name={key} error={errors[key]} className={`row-type-${fieldType}`} {...fieldProps}>
                  <Field
                    name={key}
                    error={errors[key]}
                    {...fieldProps}
                  />
                </FieldMarkup>
              )
            })}
          </form>
        </FormProvider>
      </div>
      <Footer tac={tac}/>
    </div>
  )
}

export default OnBoardingForm;
