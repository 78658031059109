import React from 'react';

import logo from '../static/img/simplifio_logo.svg';

const Home = () => {
  return (
    <div className="home">
      <img src={logo} alt="Simplifio Logo" height={300}/>
    </div>
  );
}

export default Home;
