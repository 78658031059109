import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { Toaster } from 'react-hot-toast';
import TagManager from 'react-gtm-module';

import OnBoardingForm from './components/OnboardingForm';
import NotFound from './components/NotFound';
import Home from './components/Home';

import './static/styles/main.scss';

TagManager.initialize({
  gtmId: 'GTM-5MJG3WP',
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route path="/:platform">
          <OnBoardingForm/>
        </Route>
        <Route path="*">
          <NotFound/>
        </Route>
      </Switch>
    </Router>
    <Toaster toastOptions={{
      success: {
        duration: 5000,
      },
    }}/>
  </React.StrictMode>,
  document.getElementById('root')
);
