import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import classnames from 'classnames';

interface IIndexable {
  [key: string]: any
}

export const getFieldComponent = (type: string) => {
  const map: IIndexable = {
    'string': TextField,
    'integer': IntegerField,
    'decimal': DecimalField,
    'url': TextField,
    'email': EmailField,
    'choice': SelectField,
    'boolean': CheckBoxField,
    'field': NestedArrayField,
    'nested object': NestedField,
  }

  return map[type] || null;
}


export const FieldMarkup = (props: any) => {
  return (
    <div className={classnames('form-group row', props.className, { 'has-errors': !!props.error })}>
      <div className={classnames('col-sm-3 col-form-label')}>
        <label htmlFor={props.name} className={classnames({ 'has-errors': !!props.error })}>{props.required &&
        <span className="required">*</span>} {props.label}</label>
        {props.help_text && (
          <span className={classnames('form-text small text-muted')}>
            {props.help_text}
          </span>
        )}
      </div>
      <div className="col-sm-9">
        {props.children}
      </div>
    </div>
  )
}


export const TextField = ({ name, required, max_length, error, className }: any) => {
  const { register } = useFormContext();

  return (
    <>
      <input name={name}
             id={name}
             ref={register({
               required,
               maxLength: max_length
             })}
             type="text"
             className={classnames(`form-control ${className}`, { 'is-invalid': !!error })}
      />
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const IntegerField = ({ name, required, max_length, error, className }: any) => {
  const { register } = useFormContext();
  return (
    <>
      <input name={name}
             id={name}
             ref={register({
               required,
               maxLength: max_length
             })}
             type="number"
             min="0"
             className={classnames(`form-control ${className}`, { 'is-invalid': !!error })}
      />
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const DecimalField = ({ name, required, error, className }: any) => {
  const { register } = useFormContext();
  return (
    <>
      <input name={name}
             id={name}
             ref={register({
               required
             })}
             type="number"
             min="0"
             step="0.01"
             className={classnames(`form-control ${className}`, { 'is-invalid': !!error })}
      />
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const EmailField = ({ name, required, max_length, error, className }: any) => {
  const { register } = useFormContext();
  return (
    <>
      <input name={name}
             id={name}
             type="email"
             ref={register({
               required,
               maxLength: max_length
             })}
             className={classnames(`form-control ${className}`, { 'is-invalid': !!error })}
      />
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const CheckBoxField = ({ name, error, required, className }: any) => {
  const { register } = useFormContext();
  return (
    <>
      <input name={name}
             id={name}
             type="checkbox"
             ref={register({
               required,
             })}
             className={classnames(`${className}`, { 'is-invalid': !!error })}
      />
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const SelectField = ({ name, choices, required, error, className }: any) => {
  const { register } = useFormContext();
  return (
    <>
      <select name={name}
              id={name}
              ref={register({ required })}
              className={classnames(`form-control ${className}`, { 'is-invalid': !!error })}
      >
        <option value="">---</option>
        {choices.map((opt: any) => {
          return <option key={opt.value} value={opt.value}>{opt.display_name}</option>
        })}
      </select>
      {error && (
        <small className="form-text text-danger">
          {error!.message}
        </small>
      )}
    </>
  );
}

export const NestedField = ({ name, error, children }: any) => {
  return (
    <div className="nested-row">
      {Object.keys(children).map((key: string) => {
        const fieldProps = children[key];
        const Field = getFieldComponent(fieldProps.type)

        return (
          <div key={key} className="nested-row-field">
            <label htmlFor={`[${name}][${key}]`}>{fieldProps.required &&
            <span className="required">*</span>} {fieldProps.label}</label>
            <Field
              name={`[${name}][${key}]`}
              error={error?.hasOwnProperty(key) && error}
              className="form-control-sm"
              {...fieldProps}
            />
            <small className="form-text text-muted">
              {fieldProps.help_text && fieldProps.help_text}
            </small>

            {error?.hasOwnProperty(key) && (
              <small className="form-text text-danger">
                {error[key]!.message}
              </small>
            )}

          </div>
        )
      })}
    </div>
  );
}

export const NestedArrayField = ({ name, error, child }: any) => {

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const { children }: IIndexable = child;

  return (
    <>
      {fields.map((data, index) => {
        return (
          <div key={data.id} className="child-row">
            {Object.keys(children).map((key: string) => {
              const fieldProps = children[key];
              const Field = getFieldComponent(fieldProps.type)

              return (
                <div key={`${data.id}-${key}`} className="child-row-field">
                  <label htmlFor={`[${name}][${index}][${key}]`}>{fieldProps.required &&
                  <span className="required">*</span>} {fieldProps.label}</label>
                  <Field
                    name={`[${name}][${index}][${key}]`}
                    error={error && error[index]}
                    className="form-control-sm"
                    {...fieldProps}
                  />
                  <small className="form-text text-muted">
                    {fieldProps.help_text && fieldProps.help_text}
                  </small>
                  {error && (
                    <small className="form-text text-danger">
                      {error!.message}
                    </small>
                  )}
                </div>
              )
            })}
            <div className="child-row-remove" onClick={() => remove(index)}><i className="far fa-times-circle"/></div>
          </div>
        )
      })}
      <div className="btn-toolbar mb-2 mb-md-0">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary btn-add-field"
          onClick={() => append({})}>
          <i className="fas fa-fw fa-plus-square"/> Lisää toimitustapa
        </button>
      </div>
    </>
  );
}
