import React, { useState } from 'react';

const YEAR = new Date().getFullYear();

const Footer = ({ tac }: any) => {

  const [tacVisible, setTacVisible] = useState(false);

  const toggleTac = (e: any) => {
    setTacVisible(!tacVisible);
    e.preventDefault();
  }

  return (
    <>
      {tacVisible && (
        <div className="tac-container">
          <div className="close"><i className="far fa-times-circle" onClick={(e) => toggleTac(e)}/></div>
          <div className="container">
            <div className="tac-content" dangerouslySetInnerHTML={{ __html: tac }}/>
          </div>
        </div>
      )}
      <footer className="footer">
        <div className="container d-flex justify-content-between">
          <small className="text-muted">
            &copy; <a href="https://simplifio.io" rel="noreferrer" target="_blank">Simplifio</a> {YEAR}
          </small>
          <small className="text-muted">
            {/*<a href="#" onClick={(e) => toggleTac(e)}>Käyttöehdot</a>*/}
          </small>
        </div>
      </footer>
    </>
  );
}

export default Footer;
