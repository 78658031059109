import { useLocation } from 'react-router-dom';
import { ErrorOption } from 'react-hook-form'

export const ALLOWED_PLATFORMS = [
  'epages',
]

export const getFieldSchema = async (url = '') => {
  const response = await fetch(url, {
    method: 'OPTIONS',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return response.json();
}

export const register = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });

  return {
    status: response.status,
    data: await response.json(),
  };
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const getNestedMessage = (errors: any, key: string) => {
  return Object.keys(errors[key]).map((childKey: string) => errors[key][childKey])!.join('. ')
}

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    name: any,
    error: ErrorOption,
  ) => void
) {

  return Object.keys(errors).forEach((key) => {
    let errorKey = key;

    const message: string = Array.isArray(errors[key as keyof T])
      ? errors[key as keyof T]!.join('. ')
      : getNestedMessage(errors, key);

    if (!Array.isArray(errors[key as keyof T])) {
      // @ts-ignore
      const nestedKeys = Object.keys(errors[key as keyof T]).map((child: any) => {
        return `${key}.${child}`;
      })

      errorKey = nestedKeys.join('');
    }

    setError(errorKey, {
      type: 'server',
      message,
    });
  });
}
