import React from 'react';

const Header = (props: any) => {
  return (
    <header className="header">
      <div className="container">
        <div className="header-content">
          <h1>Tervetuloa!</h1>
          <button type="submit" className="btn btn-outline-dark btn-lg" onClick={props.onSubmit}>
            Rekisteröidy <i className="far fa-check-circle"/>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
